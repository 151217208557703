<template>
  <AppLoadingSheet v-if="!initialized" />

  <app-view-container v-else class="py-4">
    <app-error-alert v-if="showError" class="pa-4" />

    <ValidationObserver v-else ref="form" tag="form" @submit.prevent="onSubmit">
      <v-card-title class="text-h3 semi-bold px-6 pt-2 d-block">
        <partner-logos height="60px" width="60px" class="my-4" />
        <span v-if="unapprovedTermsAndConditions.length > 1" class="subtitle">
          {{
            $t('optIn.updateCountHint', {
              current: currentEntryIndex + 1,
              total: unapprovedTermsAndConditions.length
            })
          }}
        </span>
        <h2>
          {{ translations.headline }}
        </h2>
        <p class="pt-4" v-html="translations.info"></p>
      </v-card-title>

      <template v-if="hasAcceptPermission">
        <v-divider class="mt-2" />
        <v-card-text class="body-1 py-2 px-6">
          <template v-if="!isInitialOptIn && currentEntry.changesHtml">
            <span class="opt-in__changes" v-html="currentEntry.changesHtml"></span>
            <v-divider class="mt-4 mb-2" />
          </template>

          <v-list v-if="currentEntry.documents.length > 0">
            <template v-for="(document, index) in currentEntry.documents">
              <v-list-item :key="index" class="pa-0">
                <v-list-item-content class="opt-in__documents">
                  <app-checkbox
                    v-model="approvedDocumentTypes[document.type]"
                    :custom-error-messages="translations.customErrorMessages"
                    required
                    class="d-flex"
                  >
                    <div>
                      {{ document.title }}
                      <file-link-btn
                        v-if="document.source"
                        :loading="loadingFileType === document.type"
                        append-icon
                        class="ml-2"
                        icon-size="1.25rem"
                        @click.stop="openFile(document.source, document.type)"
                      />

                      <span v-if="document.version" class="opt-in__version overline grey--text">
                        {{ $t('optIn.version') }} {{ document.version }}
                      </span>
                    </div>
                  </app-checkbox>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>

          <app-checkbox
            v-else
            v-model="approvedWithoutDocuments"
            :custom-error-messages="translations.customErrorMessages"
            :label="$t('optIn.acceptTerms')"
            required
          />
        </v-card-text>
      </template>

      <v-spacer />

      <v-divider />

      <v-card-actions class="px-6">
        <v-row>
          <v-col>
            <app-btn
              :title="hasAcceptPermission ? 'navigation.cancel' : 'navigation.ok'"
              block
              outlined
              @click="onCancel"
            />
          </v-col>
          <v-col v-if="hasAcceptPermission">
            <app-btn type="submit" block title="navigation.confirm" :loading="loading" />
          </v-col>
        </v-row>
      </v-card-actions>
    </ValidationObserver>
  </app-view-container>
</template>

<script>
import OpenFileMixin from '@/mixins/OpenFileMixin';
import OptInStatus from '@/modules/Authentication/statics/OptInStatus';
import FileLinkBtn from '@/shared/components/FileLinkBtn.vue';
import PartnerLogos from '@/shared/components/PartnerLogos';
import { NAMESPACE } from '@/modules/Authentication/store';
import { mapActions } from 'vuex';

export default {
  name: 'OptIn',

  mixins: [OpenFileMixin],

  components: { FileLinkBtn, PartnerLogos },

  data: () => ({
    unapprovedTermsAndConditions: [],
    approvedDocumentTypes: {},
    approvedWithoutDocuments: false,
    currentEntryIndex: 0,
    initialized: false,
    loading: false,
    showError: false
  }),

  computed: {
    hasAcceptPermission() {
      return this.permissions.optIn.accept;
    },
    isInitialOptIn() {
      return this.$auth.user().optIn === OptInStatus.NEW && this.currentEntryIndex === 0;
    },
    currentEntry() {
      return {
        ...this.unapprovedTermsAndConditions[this.currentEntryIndex],
        documents: this.isInitialOptIn
          ? this.unapprovedTermsAndConditions[this.currentEntryIndex].documents
          : this.unapprovedTermsAndConditions[this.currentEntryIndex].documents.filter(
              (document) => document.modified
            )
      };
    },
    translations() {
      return {
        headline: this.$t(this.isInitialOptIn ? 'optIn.new.headline' : 'optIn.update.headline'),
        info: this.$t(
          this.isInitialOptIn && this.hasAcceptPermission
            ? 'optIn.new.infoCreditorUser'
            : this.isInitialOptIn && !this.hasAcceptPermission
            ? 'optIn.new.infoCompanyUser'
            : !this.isInitialOptIn && this.hasAcceptPermission
            ? 'optIn.update.infoCreditorUser'
            : !this.isInitialOptIn && !this.hasAcceptPermission
            ? 'optIn.update.infoCompanyUser'
            : ''
        ),
        customErrorMessages: { required: this.$t('optIn.mustBeAccepted') }
      };
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['fetchUnapprovedTermsAndConditions', 'approveTermsAndConditions']),

    async getUnapprovedTermsAndConditions() {
      const { data, error } = await this.fetchUnapprovedTermsAndConditions();

      if (error) {
        this.showError = true;
        return;
      }

      if (data.length === 0) {
        this.showError = true;
        console.error('No unapproved terms and conditions found');
        return;
      }

      this.unapprovedTermsAndConditions = data;
    },

    setDocumentsToApprove() {
      this.approvedDocumentTypes = this.currentEntry.documents.reduce((acc, document) => {
        acc[document.type] = false;
        return acc;
      }, {});
    },

    async onCancel() {
      this.loading = true;
      await this.logout();
      this.loading = false;
    },

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.loading = true;

      const { error } = await this.approveTermsAndConditions({
        approvalId: this.currentEntry.id,
        approvedDocuments: this.approvedDocumentTypes
      });
      await this.$auth.fetch({});

      this.loading = false;

      if (error) {
        return;
      }

      if (this.currentEntryIndex + 1 < this.unapprovedTermsAndConditions.length) {
        this.currentEntryIndex += 1;
        this.approvedWithoutDocuments = false;
        this.setDocumentsToApprove();
        this.$refs.form?.reset();
        window.scrollTo(0, 0);
        return;
      }

      if (this.$auth.user() && this.$auth.user().passwordUpdateRequired) {
        return this.$router.replace({ name: this.routeName.UPDATE_PASSWORD });
      }

      this.$router.replace({ name: this.defaultRoute.route });
    }
  },

  async created() {
    await this.getUnapprovedTermsAndConditions();
    this.setDocumentsToApprove();
    this.initialized = true;
  },

  async beforeRouteLeave(to, from, next) {
    if (this.$auth.user() && this.$auth.user().optIn !== OptInStatus.ACCEPTED) {
      await this.logout();
    }

    next();
  }
};
</script>

<style scoped lang="scss">
.opt-in {
  &__changes {
    white-space: pre-line;
  }

  &__documents {
    // don't clip checkbox hover
    overflow: visible;
  }

  &__version {
    display: block;
    line-height: 1;
  }
}
</style>
