<template>
  <vue-dropzone
    id="document"
    ref="customDropzone"
    :key="current"
    :options="dropzoneOptions"
    :include-styling="false"
    use-custom-slot
    @vdropzone-sending="beforeSending"
    @vdropzone-max-files-exceeded="maxFilesExceeded"
    @vdropzone-error="uploadError"
    @vdropzone-file-added="addedFile"
    @vdropzone-removed-file="removedFile"
    @vdropzone-queue-complete="finishUpload"
    @vdropzone-success="setLastUploadedVoucherId"
  >
    <slot>
      <div
        v-if="files.length === 0"
        class="dropzone-custom-content d-flex flex-column align-center justify-space-around"
      >
        <div class="d-flex align-center justify-center icon__wrapper">
          <img
            :src="require('@/modules/Upload/assets/UploadArrow.svg')"
            width="36px"
            height="36px"
          />
        </div>
        <h3 class="dropzone-custom-title semi-bold mt-4">
          <slot name="title">
            {{ $t('upload.dropzone', { type: $t(type) }) }}
          </slot>
        </h3>
        <p class="grey--text mt-1">
          {{ $t('upload.hintDocumentTypes') }}: {{ $t(acceptedFiles.translation) }}
        </p>
        <p class="grey--text my-12">{{ $t('or') }}</p>
        <app-btn class="px-12">{{ $tc(selectFilesText, maxFiles) }}</app-btn>
        <span v-if="maxFiles" class="caption grey--text mt-2">
          {{ $tc('upload.hintMaxFilesCounted', maxFiles) }}
        </span>

        <slot name="hint"></slot>
      </div>

      <p v-else class="mb-2 text-left subtitle">{{ $t('upload.filesForUpload') }}:</p>
    </slot>
  </vue-dropzone>
</template>

<script>
import * as fileConditions from '@/statics/fileConditions';
import VueDropzone from 'vue2-dropzone';
import formatBytesToMegabytes from '@/helper/formatBytesToMegabytes';
import showSnackbar from '@/helper/showSnackbar';
import { ADMIN_TOKEN_KEY, AUTH_TOKEN_KEY } from '@/plugins/vue-auth';
import { NAMESPACE as AUTHENTICATION_NAMESPACE } from '@/modules/Authentication/store';
import { mapState } from 'vuex';

export default {
  name: 'AppDropzone',

  components: {
    VueDropzone
  },

  props: {
    acceptedFiles: {
      type: Object,
      validator: (value) => value.mimeTypes && value.translation,
      default: () => ({
        mimeTypes: fileConditions.FILE_MIME_TYPES_DEFAULT,
        translation: 'upload.hintDocumentTypesDefault'
      })
    },
    // provides additional keys to the API via (as default only file and fileName are submitted)
    additionalFormData: {
      type: Object,
      default: null
    },
    hasEmptyQueue: {
      type: Boolean,
      default: true
    },
    selectFilesText: {
      type: String,
      default: 'upload.selectVouchersCounted'
    },
    // the key for the file which is submitted to the API
    fileParam: {
      type: String,
      default: 'file'
    },
    maxFiles: {
      type: Number,
      default: 1
    },
    startUpload: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'invoices'
    },
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      files: [],
      dropzoneOptions: this.getDropzoneOptions(),
      loading: false,
      error: false,
      lastUploadedVoucherId: ''
    };
  },

  computed: {
    ...mapState(AUTHENTICATION_NAMESPACE, ['current'])
  },

  watch: {
    current() {
      this.dropzoneOptions = this.getDropzoneOptions();
    },
    startUpload() {
      if (this.startUpload) {
        this.sendFile();
      }
    },
    files: {
      immediate: true,
      handler() {
        this.$emit('input', this.files);
        this.$emit(
          'update:hasEmptyQueue',
          this.files.filter((file) => file.status !== 'error').length === 0
        );
      }
    },
    value() {
      if (this.value.length === 0 && this.files.length > 0) {
        this.$refs.customDropzone.removeAllFiles();
      }
    }
  },

  methods: {
    getDropzoneOptions() {
      return {
        createImageThumbnails: true,
        url: 'none', // dropzone throws error if none is provided
        acceptedFiles: this.acceptedFiles.mimeTypes,
        autoProcessQueue: false,
        duplicateCheck: true,
        timeout: 3600000,
        parallelUploads: 1,
        maxFiles: this.maxFiles,
        paramName: this.fileParam,
        previewTemplate: this.getTemplate(),
        dictInvalidFileType: this.$t('upload.fileError'),
        dictMaxFilesExceeded: this.$t('upload.maxFilesExceeded'),
        accept: (file, done) => {
          if (file.size < fileConditions.MIN_FILE_SIZE) {
            return done(this.$t('fileIsEmpty'));
          }

          if (file.size > fileConditions.MAX_FILE_SIZE) {
            return done(
              this.$t('fileTooLarge', { max: formatBytesToMegabytes(fileConditions.MAX_FILE_SIZE) })
            );
          }

          done();
        }
      };
    },
    sendFile() {
      this.loading = true;
      this.error = false;
      this.$refs.customDropzone.setOption('headers', {
        Authorization: `Bearer ${this.$auth.token()}`,
        'X-User-ID': this.$auth.user().id,
        'X-User-Token': this.$auth.token(AUTH_TOKEN_KEY),
        'X-Admin-Token': this.$auth.token(ADMIN_TOKEN_KEY)
      });
      this.$refs.customDropzone.setOption('url', this.url);
      this.$refs.customDropzone.processQueue();
    },

    beforeSending(file, xhr, formData) {
      if (this.additionalFormData !== null) {
        Object.keys(this.additionalFormData).forEach((key) =>
          formData.append(key, this.additionalFormData[key])
        );
      }
      formData.append('fileName', file.name);
    },

    finishUpload() {
      if (!this.loading || this.error) {
        this.loading = false;
        this.error = false;
        // prevent success screen, if user triggers complete-events by dropping files of not accepted type
        return;
      }

      this.$emit('success', this.lastUploadedVoucherId);
      this.loading = false;
    },

    setLastUploadedVoucherId(_, response) {
      this.lastUploadedVoucherId = response.voucherIdentifier;
    },

    maxFilesExceeded() {
      showSnackbar({ text: 'upload.maxFilesExceeded' });
    },

    uploadError(file, error) {
      this.error = true;
      this.loading = false;
      this.$emit('error');

      if (!!file.xhr || (file.status !== 'canceled' && typeof error === 'object')) {
        file.previewElement.querySelectorAll('.dz-error-message span')[0].textContent =
          this.$t('upload.uploadError');
      }
    },

    addedFile(file) {
      this.files.push(file);
    },

    removedFile(fileToRemove) {
      this.files.splice(
        this.files.findIndex((file) => file.name === fileToRemove.name),
        1
      );
    },

    manuallyAddFile(file) {
      this.$refs.customDropzone.addFile(file);
    },

    manuallyRemoveFiles() {
      this.$refs.customDropzone.removeAllFiles();
    },

    getTemplate() {
      return `
        <div class="dz-preview">
          <div class="dz-details">
            <div class="dz-filename hide-overflow">
              <span data-dz-name></span>
              <div class="dz-error-message">- <span data-dz-errormessage></span></div>
            </div>
            <div class="tools">
              <div class="dz-progress">
                <span class="dz-upload" data-dz-uploadprogress></span>
              </div>
              <div data-dz-remove>
                <button type="button" class="v-btn v-btn--flat v-btn--icon v-btn--round theme--light v-size--default">
                    <span class="v-btn__content">
                        <i aria-hidden="true" class="v-icon notranslate mdi mdi-close theme--light grey--text"></i>
                    </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      `;
    }
  }
};
</script>

<style lang="scss">
#document {
  border: 1px dashed var(--c-grey);
  padding: 4rem 2rem 3rem 2rem;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 100%;

  .mdi {
    font-size: 1.2rem;
  }

  .dz-message {
    min-height: 100%;
    width: 100%;
  }

  .dropzone-custom-content {
    min-height: 100%;
    cursor: pointer;

    .icon__wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 4px solid grey;
      border-radius: 50%;
      width: 76px;
      height: 76px;
    }
  }

  .dz-error-message {
    display: none;
    color: red;
  }

  .dz-error .dz-error-message {
    display: inline-block;
  }

  .dz-preview {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: space-between;

    .dz-details {
      max-width: 100%;
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-align: left;

      .dz-filename {
        flex: 0 1 80%;
        word-break: keep-all;
      }

      .tools {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1 0 calc(20% - 1rem);
      }
    }

    .dz-progress {
      opacity: 1;
      z-index: 100;
      height: 16px;
      width: 80px;
      background-color: #efefef;
      -webkit-transform: scale(1);
      border-radius: 8px;
      overflow: hidden;

      .dz-upload {
        background-color: var(--c-secondary);
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 0;
        transition: width 300ms ease-in-out;
      }
    }
  }

  .dz-preview.dz-complete .dz-progress {
    opacity: 0;
    -webkit-transition: opacity 0.4s ease-in;
    transition: opacity 0.4s ease-in;
  }

  .dz-preview.dz-processing .dz-progress {
    opacity: 1;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
}
</style>
