<template>
  <div>
    <app-dialog
      v-model="open"
      title="debtors.editLocation"
      primary-action-text="navigation.save"
      secondary-action-text="navigation.cancel"
      :loading="loading"
      @submit="onSubmit"
    >
      <ValidationObserver ref="form" tag="form">
        <v-container class="pa-0">
          <app-alert v-if="error" class="mb-4">
            {{ $t('debtors.editDebtorError') }}
          </app-alert>

          <v-row>
            <v-col cols="12">
              <app-text-field
                v-model="formData.name"
                :label="debtor.isBusiness ? 'company' : 'firstAndLastName'"
                required
              />
            </v-col>

            <v-col v-if="debtor.isBusiness" cols="12">
              <app-text-field v-model="formData.contactPerson" label="contactPerson" />
            </v-col>

            <v-col cols="8">
              <app-text-field
                v-model="formData.normalizedAddress.street"
                label="street"
                :required="!isAdmin"
              />
            </v-col>

            <v-col cols="4">
              <app-text-field
                v-model="formData.normalizedAddress.house"
                label="house"
                :required="!isAdmin"
              />
            </v-col>

            <v-col cols="4">
              <app-text-field
                v-model="formData.normalizedAddress.zip"
                label="zipCode"
                rules="required|numeric|length:5"
              />
            </v-col>

            <v-col cols="8">
              <app-text-field v-model="formData.normalizedAddress.city" label="city" required />
            </v-col>

            <v-col cols="12">
              <app-text-field
                v-model="formData.customDebtorNumber"
                label="debtors.customDebtorNumber"
              />
            </v-col>

            <v-col cols="12">
              <app-text-field v-model="formData.contact.phone" label="phoneNumber" rules="phone" />
            </v-col>

            <v-col cols="12">
              <app-text-field
                v-model="formData.delivery.email"
                label="email"
                mode="lazy"
                rules="email"
                :required="deliveryTypeEmailSelected"
              />
            </v-col>

            <v-col cols="12">
              <app-select
                v-model="formData.delivery.type"
                :items="deliveryTypes"
                label="debtors.deliveryType"
                required
              />
            </v-col>
          </v-row>
        </v-container>
      </ValidationObserver>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      secondary-action-text="navigation.backToLocation"
      title="debtors.editedLocation"
      @close="hideAfterSuccess"
    />
  </div>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import filterChangedProperties from '@/helper/filterChangedProperties';
import { NAMESPACE } from '@/modules/Debtors/store';
import { mapActions } from 'vuex';
import { DELIVERY_TYPES, DeliveryType } from '@/statics/deliveryType';

export default {
  name: 'EditLocationDialog',

  mixins: [ShowDialogMixin],

  props: {
    debtor: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      formData: this.createFormData(),
      oldLocationData: this.createFormData()
    };
  },

  computed: {
    deliveryTypes() {
      return DELIVERY_TYPES.map((type) => ({
        label: this.$t(`addresses.deliveryTypes.${type}`),
        value: type
      }));
    },
    deliveryTypeEmailSelected() {
      return this.formData.delivery.type === DeliveryType.EMAIL;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['editLocation']),

    createFormData() {
      return {
        name: this.debtor.name ?? '',
        customDebtorNumber: this.debtor.customNumber ?? '',
        contactPerson: this.debtor.contactPerson ?? '',
        normalizedAddress: {
          street: this.debtor.address.street ?? '',
          house: this.debtor.address.house ?? '',
          city: this.debtor.address.city ?? '',
          zip: this.debtor.address.zip ?? ''
        },
        delivery: {
          type: this.debtor.deliveryType ?? '',
          email: this.debtor.email ?? ''
        },
        contact: {
          phone: this.debtor.phone ?? ''
        }
      };
    },

    async onSubmit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      let payload = filterChangedProperties(this.formData, this.oldLocationData);

      if (Object.keys(payload).length === 0) {
        this.submitted = true;
        return;
      }

      this.loading = true;

      const { error } = await this.editLocation({ locationIdentifier: this.debtor.id, payload });

      this.loading = false;
      if (error) {
        this.error = true;
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
