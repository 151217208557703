import { OptInDocumentType } from '@/modules/Authentication/statics/OptInDocumentType';

export default {
  login: {
    title: 'Login',
    login: 'Anmelden',
    logout: 'Ausloggen',
    register: 'Registrieren',
    welcomeBack: 'Willkommen zurück in Ihrem {portalName}!',
    badCredentials:
      'Benutzername oder Passwort falsch. Wenn Sie Probleme beim Login haben, wenden Sie sich bitte an unseren Support.',
    forgotPassword: 'Passwort vergessen',
    interestedInAccount: 'Interesse an einem Account?',
    informNow: 'Jetzt informieren',
    privacyPolicy: 'Datenschutz',
    imprint: 'Impressum'
  },

  resetPassword: {
    title: 'Passwort zurücksetzen',
    backToLogin: 'zurück zum Anmelden',
    info: 'Vergeben Sie ein neues Passwort, um Ihren Account zu schützen.',
    passwordRequirements:
      'Ihr Passwort muss mindestens 8 Zeichen lang sein und Zahlen sowie Sonderzeichen enthalten.',
    resetPasswordHelp:
      'Geben Sie Ihre E-Mail-Adresse ein, um einen Link zum Zurücksetzen Ihres Passwortes zu erhalten.',
    passwordHint:
      'Zur Erinnerung: Ihr Passwort ist mindestens 8 Zeichen lang, enthält ein Sonderzeichen, eine Zahl und einen Großbuchstaben',
    sentMail:
      'Wir haben Ihre Anfrage erhalten und senden Ihnen eine E-Mail mit weiteren Anweisungen zum Zurücksetzen Ihres Passwortes.',
    updateNow: 'Jetzt Passwort ändern',
    repeatPassword: 'Passwort wiederholen',
    updateComplete: 'Passwort geändert',
    confirmUpdate: 'Vielen Dank! Ihr Passwort wurde geändert.',
    confirmReset:
      'Ihr Passwort wurde zurückgesetzt. Sie können jetzt zum Login zurückkehren, um sich mit Ihren neuen Daten anzumelden.',
    oldPasswordUsed:
      'Das eingegebene Passwort stimmt mit dem alten überein. Geben Sie ein neues Passwort ein.',
    requestExpired:
      'Die Anfrage ist nicht mehr gültig. Bitte fordern Sie das Zurücksetzen des Passwortes neu an.',
    startNewRequest: 'Neuen Link anfordern'
  },

  optIn: {
    documentTypes: {
      [OptInDocumentType.TERMS_AND_CONDITIONS]: 'AGB',
      [OptInDocumentType.DATA_PROCESSING_AGREEMENT]: 'AV-Vereinbarung',
      [OptInDocumentType.TERMS_OF_USE]: 'Nutzungsbedingungen',
      [OptInDocumentType.ENCASHMENT_CONDITIONS]: 'Inkassovereinbarung'
    },
    version: 'Version',
    updateCountHint: 'Änderung {current} von {total}',
    acceptTerms: 'Ich akzeptiere alle Bedingungen und Konditionen',
    mustBeAccepted: 'Ihre Bestätigung ist erforderlich',
    new: {
      headline: 'Dienstleistungsbedingungen',
      infoCreditorUser:
        'Bitte bestätigen Sie die unten aufgeführten Dienstleistungsbedingungen. Anschließend werden Sie zum Portal weitergeleitet.',
      infoCompanyUser:
        'Sehr geehrter Benutzer,<br /> <br/> bevor Sie sich bei uns anmelden können, müssen zunächst unsere Dienstleistungsbedingungen bestätigt werden. Dies muss durch den Hauptbenutzer unseres Portals erfolgen. Bei Fragen wenden Sie sich gerne an unseren Kundenservice.'
    },
    update: {
      headline: 'Änderung unserer Dienstleistungsbedingungen',
      infoCreditorUser:
        'Unsere Dienstleistungsbedingungen haben sich geändert. Bitte bestätigen Sie die neuen Bedingungen. Anschließend werden Sie zum Portal weitergeleitet.',
      infoCompanyUser:
        'Sehr geehrter Benutzer,<br /> <br/> unsere Dienstleistungsbedingungen haben sich geändert. Bevor Sie sich wie gewohnt bei uns anmelden können, müssen diese zunächst bestätigt werden. Dies muss durch den Hauptbenutzer unseres Portals erfolgen. Bei Fragen wenden Sie sich gerne an unseren Kundenservice.'
    }
  },

  reload: {
    reloadPage: 'Seite aktualisieren',
    currentUserChanged: 'Änderung Ihrer Nutzerdaten festgestellt',
    info: 'Ihre Nutzerdaten haben sich seit dem Login geändert. Dies tritt zum Beispiel dann auf, wenn Sie das Portal in mehreren Tabs geöffnet haben und dabei unterschiedliche Zugänge nutzen.<br /><br />Zum Schutz Ihrer Daten bitten wir Sie, die Seite zu aktualisieren, um in Ihrem Portal fortzufahren.'
  }
};
