<template>
  <div>
    <p class="text-h5 mb-1">{{ $t('customisedLabel') }}</p>
    <ValidationObserver v-if="isEditable" ref="form" tag="form" @submit.prevent>
      <v-row dense class="mt-2" align="baseline">
        <v-col cols="6">
          <app-text-field
            v-model="newCustomisedLabel"
            name="customisedLabel"
            label="customisedLabel"
            rules="max:60"
          />
        </v-col>
        <v-col v-if="customisedLabelHasChanged" cols="6">
          <app-btn
            :loading="loading"
            class="save__button"
            type="submit"
            @click="submitCustomisedLabel"
          >
            {{ $t('navigation.take') }}
          </app-btn>
        </v-col>
      </v-row>
    </ValidationObserver>
    <span v-else>
      {{ selectionText }}
    </span>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Unprocessed/store';

export default {
  name: 'CustomisedLabel',

  props: {
    customisedLabel: {
      type: String,
      default: ''
    },
    details: {
      type: Object,
      required: true
    },
    isEditable: {
      type: Boolean,
      required: true
    }
  },

  data: () => ({
    newCustomisedLabel: '',
    loading: false
  }),

  computed: {
    customisedLabelHasChanged() {
      return this.newCustomisedLabel !== this.details.customisedLabel;
    },
    selectionText() {
      return this.details.customisedLabel;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, [
      'fetchUnprocessedList',
      'submitCustomisedLabel',
      'editCustomisedLabel'
    ]),

    async submitCustomisedLabel() {
      this.loading = true;

      if (!(await this.$refs.form.validate())) {
        this.loading = false;
        return;
      }

      const { error } = await this.editCustomisedLabel({
        identifier: this.$route.params.id,
        customisedLabelData: {
          customisedLabel: this.newCustomisedLabel
        }
      });

      if (error) {
        return;
      }

      await this.fetchUnprocessedList();

      if (this.$refs.form) {
        this.$refs.form?.reset();
      }
      this.loading = false;
    }
  },

  created() {
    this.newCustomisedLabel = this.details.customisedLabel;
  }
};
</script>

<style scoped>
.save__button {
  height: 56px;
}
</style>
