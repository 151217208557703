<template>
  <v-container fluid class="contract">
    <v-row>
      <v-col :cols="isMobile ? 12 : 6" class="tab__col">
        <h3>{{ $t('masterData.generalInformation') }}</h3>
        <app-labeled-data
          :label="`${$t('masterData.customerNumber')} / ${$t('masterData.contractNumber')}`"
          :value="creditorDetails.contract.contractNumber"
        />
        <app-labeled-data label="masterData.startOfContract">
          {{ creditorDetails.contract.contractStart | formatDate }}
        </app-labeled-data>
        <app-labeled-data v-if="isFactoring">
          <template #label>
            <div class="d-flex align-center">
              {{ $t('masterData.approvedLimit') }}
              <app-tooltip text="masterData.increaseCreditorLimitInfo" />
            </div>
          </template>
          {{ creditorDetails.contract.approvedLimit | formatCurrency }}
        </app-labeled-data>

        <template
          v-if="
            permissions.masterData.serviceFeesWithPurchaseFees ||
            permissions.masterData.serviceFeesWithoutPurchaseFees
          "
        >
          <h6 class="d-block subtitle text-uppercase mt-10 mb-2">{{ $t('serviceFees.fees') }}</h6>
          <template v-if="permissions.masterData.serviceFeesWithPurchaseFees">
            <app-labeled-data label="serviceFees.purchasedFee">
              {{
                creditorDetails.contract.serviceFees.serviceFeeMatrix.purchasedFee
                  | formatPercentage
              }}
            </app-labeled-data>
            <app-labeled-data label="serviceFees.notPurchasedFee">
              {{ notPurchasedFee }}
            </app-labeled-data>
            <app-labeled-data label="serviceFees.purchasedLaterFee">
              {{
                creditorDetails.contract.serviceFees.serviceFeeMatrix.purchasedLaterFee
                  | formatPercentage
              }}
            </app-labeled-data>
          </template>
          <template v-else>
            <app-labeled-data label="serviceFees.serviceFee">
              {{ notPurchasedFee }}
            </app-labeled-data>
          </template>
          <app-labeled-data label="serviceFees.minimumServiceFee">
            {{
              creditorDetails.contract.serviceFees.serviceFeeMatrix.minimumServiceFee
                | formatCurrency
            }}
          </app-labeled-data>

          <template v-if="creditorDetails.serviceFeePeriod">
            <app-labeled-data label="serviceFees.annualServiceFee">
              {{
                creditorDetails.serviceFeePeriod.currentPeriod.chargedServiceFee | formatCurrency
              }}
              {{ $t('serviceFees.of') }}
              {{ creditorDetails.serviceFeePeriod.currentPeriod.serviceFee | formatCurrency }}
              <br />
              ({{
                creditorDetails.serviceFeePeriod.currentPeriod.chargedPercentServiceFee
                  | formatPercentage
              }})
            </app-labeled-data>
            <app-labeled-data label="serviceFees.periodStart">
              {{ creditorDetails.serviceFeePeriod.currentPeriod.start | formatDate }}
            </app-labeled-data>
            <app-labeled-data label="serviceFees.periodEnd">
              {{ creditorDetails.serviceFeePeriod.currentPeriod.end | formatDate }}
            </app-labeled-data>
          </template>
        </template>

        <h3 class="mt-12">{{ $t('documentPl') }}</h3>
        <template v-for="document in creditorDetails.documents">
          <v-row :key="document.title" dense>
            <v-col>
              <file-link-btn
                :disabled="!document.source"
                :loading="loadingFileType === document.type"
                :title="document.title"
                icon-size="20"
                icon="icon-file-pdf"
                prepend-icon
                @click="openFile(document.source, document.type)"
              />

              <span v-if="document.version" class="overline grey--text ml-2">
                {{ $t('optIn.version') }} {{ document.version }}
              </span>
            </v-col>
          </v-row>
        </template>
        <template v-for="document in product.documents.masterData">
          <v-row :key="document.title" dense>
            <v-col>
              <file-link-btn
                :disabled="!document.path"
                :href="document.path"
                :icon="document.path.substr(-4).includes('doc') ? 'icon-file-doc' : 'icon-file-pdf'"
                :title="document.title"
                icon-size="20"
                prepend-icon
              />
            </v-col>
          </v-row>
        </template>
      </v-col>
      <v-col :cols="isMobile ? 12 : 6" class="tab__col">
        <div class="d-flex align-center">
          <h3>
            {{ $t('history') }}
          </h3>
          <app-flat-badge class="align-inline" :value="creditorDetails.history.length" />
        </div>

        <history :data="mapHistory(creditorDetails.history)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AppFlatBadge from '@/shared/components/AppFlatBadge';
import FileLinkBtn from '@/shared/components/FileLinkBtn';
import History from '@/shared/components/History';
import HistoryMixin from '@/mixins/HistoryMixin';
import OpenFileMixin from '@/mixins/OpenFileMixin';
import formatPercentage from '@/helper/filter/formatPercentage';

export default {
  name: 'TabContract',

  mixins: [HistoryMixin, OpenFileMixin],

  components: {
    AppFlatBadge,
    FileLinkBtn,
    History
  },

  props: {
    creditorDetails: {
      type: Object,
      required: true
    }
  },

  computed: {
    notPurchasedFee() {
      const { notPurchasedFee, notPurchasedMinimumFee } =
        this.creditorDetails.contract.serviceFees.serviceFeeMatrix;

      if (notPurchasedFee === notPurchasedMinimumFee) {
        return formatPercentage(notPurchasedFee);
      }

      return this.$t('serviceFees.notPurchasedFeeWithMinimum', {
        notPurchasedFee: formatPercentage(notPurchasedFee),
        notPurchasedMinimumFee: formatPercentage(notPurchasedMinimumFee)
      });
    }
  }
};
</script>
