<template>
  <div>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.ASSET_CONSULTANT)" cols="12">
        <app-radio-group
          id="creditor-registration__asset-consultant"
          v-model="intermediaryData[fields.ASSET_CONSULTANT]"
          :items="assetConsultantItems"
          :rules="getRules(fields.ASSET_CONSULTANT, 'required')"
          label="onboarding.mediatedByAssetConsultant"
        />
      </v-col>
    </v-row>

    <p class="mt-4">{{ $t('onboarding.assetConsultantData') }}</p>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.CONTACT_SALUTATION)" cols="12" md="2">
        <app-salutation-select
          id="creditor-registration__intermediary-salutation"
          v-model="intermediaryData[fields.CONTACT_SALUTATION]"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.CONTACT_FIRST_NAME)" cols="12" md="5">
        <app-text-field
          id="creditor-registration__intermediary-first-name"
          v-model="intermediaryData[fields.CONTACT_FIRST_NAME]"
          label="firstName"
          :rules="getRules(fields.CONTACT_FIRST_NAME, 'required')"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.CONTACT_LAST_NAME)" cols="12" md="5">
        <app-text-field
          id="creditor-registration__intermediary-last-name"
          v-model="intermediaryData[fields.CONTACT_LAST_NAME]"
          label="lastName"
          :rules="getRules(fields.CONTACT_LAST_NAME, 'required')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.INTERMEDIARY_STREET_AND_HOUSE)" cols="12">
        <app-text-field
          id="creditor-registration__intermediary-street-and-house"
          v-model="intermediaryData[fields.INTERMEDIARY_STREET_AND_HOUSE]"
          label="streetAndHouse"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.INTERMEDIARY_ZIP)" cols="12" md="3">
        <app-text-field
          id="creditor-registration__intermediary-zip"
          v-model="intermediaryData[fields.INTERMEDIARY_ZIP]"
          label="zip"
          :rules="getRules(fields.INTERMEDIARY_ZIP, 'required|numeric|length:5')"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.INTERMEDIARY_CITY)" cols="12" md="9">
        <app-text-field
          id="creditor-registration__intermediary-city"
          v-model="intermediaryData[fields.INTERMEDIARY_CITY]"
          label="city"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.INTERMEDIARY_PHONE)" cols="12">
        <app-text-field
          id="creditor-registration__intermediary-phone"
          v-model="intermediaryData[fields.INTERMEDIARY_PHONE]"
          label="phone"
          :rules="getRules(fields.INTERMEDIARY_PHONE, 'required|phone')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.INTERMEDIARY_EMAIL)" cols="12">
        <app-text-field
          id="creditor-registration__intermediary-email"
          v-model="intermediaryData[fields.INTERMEDIARY_EMAIL]"
          label="email"
          :rules="getRules(fields.INTERMEDIARY_EMAIL, 'required|email')"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.DEPARTMENT)" cols="12">
        <app-text-field
          id="creditor-registration__intermediary-department"
          v-model="intermediaryData[fields.DEPARTMENT]"
          label="onboarding.department"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="dataNotBlacklisted(fields.ASSET_CONSULTANT_NUMBER)" cols="12" md="6">
        <app-text-field
          id="creditor-registration__asset-consultant-number"
          v-model="intermediaryData[fields.ASSET_CONSULTANT_NUMBER]"
          label="onboarding.assetConsultantNumber"
          :rules="getRules(fields.ASSET_CONSULTANT_NUMBER, 'required|digits:7')"
        />
      </v-col>
      <v-col v-if="dataNotBlacklisted(fields.INTERMEDIARY_NUMBER)" cols="12" md="6">
        <app-text-field
          id="creditor-registration__intermediary-number"
          v-model="intermediaryData[fields.INTERMEDIARY_NUMBER]"
          label="onboarding.intermediaryNumber"
          :rules="getRules(fields.INTERMEDIARY_NUMBER, 'digits:7')"
        />
      </v-col>
    </v-row>
    <v-row v-if="isOnboarding && dataNotBlacklisted(fields.NOTES)">
      <v-col cols="12">
        <app-textarea
          id="creditor-registration__notes"
          v-model="intermediaryData[fields.NOTES]"
          label="onboarding.notes"
          rows="3"
          background-color="white"
        />
      </v-col>
    </v-row>
    <v-row v-if="isOnboarding && dataNotBlacklisted(fields.PRIVACY_POLICY_ACCEPTED)">
      <v-col cols="12">
        <app-checkbox
          id="onboarding-registration__privacy-policy-confirmation"
          v-model="intermediaryData[fields.PRIVACY_POLICY_ACCEPTED]"
          label="onboarding.privacyPolicyConfirmation"
          required
        >
          <i18n path="onboarding.privacyPolicyAccepted">
            <template #link>
              <a :href="privacyPolicyUrl" class="privacy-policy-link" target="_blank" @click.stop>
                {{ privacyPolicyUrl }}
              </a>
            </template>
          </i18n>
        </app-checkbox>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppSalutationSelect from '@/shared/components/AppSalutationSelect';
import OnboardingProductMixin from '@/mixins/OnboardingProductMixin';
import { ASSET_CONSULTANTS } from '@/statics/assetConsultants';
import { PRIVACY_POLICY_FINTECRITY } from '@/statics/customerServiceInformation';

export default {
  name: 'Intermediary',

  mixins: [OnboardingProductMixin],

  components: {
    AppSalutationSelect
  },

  props: {
    formData: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    intermediaryData: {}
  }),

  computed: {
    assetConsultantItems() {
      return ASSET_CONSULTANTS.map((consultant) => ({
        value: consultant,
        label: consultant,
        id: `creditor-registration__asset-consultant--${consultant}`
      }));
    },
    privacyPolicyUrl() {
      return PRIVACY_POLICY_FINTECRITY;
    }
  },

  watch: {
    formData: {
      handler() {
        if (
          JSON.stringify(this.formData[this.fields.INTERMEDIARY]) ===
          JSON.stringify(this.intermediaryData)
        ) {
          return;
        }

        this.intermediaryData = { ...this.formData[this.fields.INTERMEDIARY] };
      },
      immediate: true,
      deep: true
    },
    intermediaryData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          [this.fields.INTERMEDIARY]: value
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.privacy-policy-link {
  display: inline-block;
  word-break: break-word;
}
</style>
