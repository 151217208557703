<template>
  <v-container class="px-0">
    <app-input-row dense label="subject">
      <app-text-field v-model="serviceData.subject" required name="subject" />
    </app-input-row>

    <app-input-row dense label="permanentDebts.customNumber" sub-label="optional">
      <app-text-field v-model="serviceData.customNumber" />
    </app-input-row>

    <app-input-row dense label="description" sub-label="optional">
      <app-textarea v-model="serviceData.description" rows="2" />
    </app-input-row>

    <app-input-row dense label="attachment" sub-label="optional">
      <app-file-input
        v-model="serviceData.attachment"
        :name="$t('attachment')"
        align-right
        placeholder="permanentDebts.uploadContract"
        prepend-inner-icon
        small
      />
    </app-input-row>
  </v-container>
</template>

<script>
export default {
  name: 'ServiceForm',

  props: {
    formData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      serviceData: {
        ...this.formData.service
      }
    };
  },

  watch: {
    serviceData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          service: value
        });
      },
      deep: true
    }
  }
};
</script>
