<template>
  <ValidationProvider v-slot="{ errors }" v-bind="validationProps">
    <v-file-input
      :id="fieldId"
      v-model="file"
      outlined
      :append-icon="appendIcon && !file ? 'mdi-paperclip' : null"
      :clearable="false"
      :label="fieldLabelWithSupplement"
      :placeholder="fieldPlaceholder"
      :accept="accept"
      :error-messages="errors"
      :prepend-icon="prependIcon ? 'mdi-paperclip' : null"
      :prepend-inner-icon="prependInnerIcon && !file ? 'mdi-paperclip' : null"
      :hide-details="errors.length === 0"
      v-bind="$attrs"
      :class="{
        'input--align-right': alignRight,
        'input--small': small,
        'input--has-error': errors.length > 0,
        'input--prepend-inner': prependInnerIcon
      }"
    >
      <template #selection="{ text }">
        <span class="d-flex justify-space-between flex-grow-1" style="white-space: nowrap">
          {{ text }}
          <v-icon @click.stop="file = null">mdi-close</v-icon>
        </span>
      </template>
    </v-file-input>
  </ValidationProvider>
</template>

<script>
import * as fileConditions from '@/statics/fileConditions';
import FormValidationMixin from '@/mixins/FormValidationMixin';

export default {
  name: 'AppFileInput',

  mixins: [FormValidationMixin],

  props: {
    accept: {
      type: String,
      default: fileConditions.FILE_MIME_TYPES_DEFAULT
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    appendIcon: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'lazy'
    },
    prependIcon: {
      type: Boolean,
      default: false
    },
    prependInnerIcon: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    value: {
      type: File,
      default: null
    }
  },

  computed: {
    file: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    fileValidationRules() {
      return `min_size:${fileConditions.MIN_FILE_SIZE}|max_size:${fileConditions.MAX_FILE_SIZE}|${this.validationRules}|file_type:${this.accept}`;
    },
    validationProps() {
      return {
        name: this.validationName,
        rules: this.fileValidationRules,
        mode: this.mode,
        vid: this.vid ?? this.fieldId,
        customMessages: this.customErrorMessages
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import '~@/styles/mixins/textFieldStyle';

::v-deep .v-file-input {
  @include AppTextFieldMixin;

  &.input--align-right .v-file-input__text {
    justify-content: flex-end;
  }
}
</style>
