<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      :primary-action-text="getPrimaryActionText('vouchers.credit.confirmCredit')"
      secondary-action-text="navigation.cancel"
      title="vouchers.credit.checkCredit"
      text="vouchers.credit.info"
      :disabled="formIncomplete || failed"
      :loading="loading"
      @submit="submitCredit"
    >
      <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
        <slot :name="name" v-bind="slotData"></slot>
      </template>

      <v-container class="px-0">
        <v-row dense align="center" class="mt-4 semi-bold">
          <v-col cols="6" class="semi-bold">{{ voucher.numberToShow }}:</v-col>
          <v-col cols="6" class="text-right">
            {{ voucher.leftoverAmount | formatCurrency }}
          </v-col>
        </v-row>
        <v-divider class="mb-5" />

        <app-input-row>
          <template #label>
            <div class="d-flex align-center flex-nowrap">
              {{ $t('vouchers.customNumber') }}:
              <app-tooltip text="vouchers.credit.voucherNumberInfo">
                <icon-info class="ml-2 grey--text" height="15px" width="15px" />
              </app-tooltip>
            </div>
          </template>

          <app-text-field
            v-model="voucherNumber"
            name="vouchers.customNumber"
            rules="required|max:20"
            placeholder="G_12345"
            align-right
          />
        </app-input-row>

        <app-input-row label="amount">
          <app-number-field
            v-model="amount"
            name="amount"
            :rules="`required|max_currency:${voucher.leftoverAmount}`"
            prepend-inner-icon="mdi-currency-eur"
            placeholder="0,00"
            align-right
          />

          <template #append>
            <app-link-btn
              title="vouchers.takeLeftoverAmount"
              variant="muted"
              @click="amount = voucher.leftoverAmount"
            />
          </template>
        </app-input-row>

        <app-input-row label="date">
          <app-date-picker
            v-model="voucherDate"
            name="vouchers.voucherDate"
            placeholder="dateHint"
            required
            prepend-inner-icon
            align-right
          />
        </app-input-row>

        <app-input-row label="voucher">
          <app-file-input
            v-model="file"
            :name="$t('voucher')"
            placeholder="choosePDF"
            prepend-inner-icon
            align-right
            required
          />
        </app-input-row>

        <v-divider class="mt-5" />
        <v-row class="semi-bold" dense justify="space-between">
          <v-col cols="6">{{ $t('vouchers.abandonment.newLeftover') }}:</v-col>
          <v-col class="text-right">
            {{ (voucher.leftoverAmount - amount) | formatCurrency }}
          </v-col>
        </v-row>
      </v-container>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.credit.confirmedCredit"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    >
      <p>{{ $t('vouchers.credit.creditReceived') }}</p>
    </app-dialog>
  </ValidationObserver>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import WorkQueueFollowUpMixin from '@/mixins/WorkQueueFollowUpMixin';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';
import { formatDateToTimestamp, formatTimestampToDate } from '@/helper/filter/formatDate';

export default {
  name: 'CreditDialog',

  mixins: [ShowDialogMixin, WorkQueueFollowUpMixin],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    voucherNumber: '',
    amount: null,
    voucherDate: '',
    file: null
  }),

  computed: {
    formIncomplete() {
      return !this.voucherDate || !this.file || !this.amount || !this.voucherNumber;
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['generateCredit']),

    async submitCredit() {
      if (!(await this.$refs.form.validate())) {
        return;
      }
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('fileName', this.file.name);
      formData.append('voucherNumber', this.voucherNumber);
      formData.append('amount', this.amount);
      formData.append('voucherDate', formatDateToTimestamp(this.voucherDate));

      if (this.emitData) {
        this.$emit('submit', formData);
        return;
      }

      this.loading = true;
      const { error } = await this.generateCredit({
        voucherIdentifier: this.$route.params.id,
        formData
      });
      this.loading = false;

      if (error) {
        return;
      }
      this.submitted = true;
      this.amount = null;
      this.voucherDate = '';
      this.voucherNumber = '';
      this.file = null;
      this.$refs.form?.reset();
    }
  },

  created() {
    if (!this.prefillData) {
      return;
    }

    for (let element of this.prefillData.entries()) {
      this[element[0]] = element[1];
    }
    this.voucherDate = formatTimestampToDate(this.voucherDate);
  }
};
</script>
