import i18n from '@/helper/i18n';

export const mapUnapprovedTermsAndConditions = (list) =>
  list.map((entry) => ({
    id: entry.identifier,
    documents: mapTermsAndConditionsDocuments(entry.documents),
    changesHtml: entry.changesText
  }));

export const mapTermsAndConditionsDocuments = (documents) =>
  documents.map((document) => ({
    type: document.documentType,
    source: document.source,
    title: i18n.te(`optIn.documentTypes.${document.documentType}`)
      ? i18n.t(`optIn.documentTypes.${document.documentType}`)
      : i18n.t('document'),
    version: document.version,
    modified: document.modified
  }));
