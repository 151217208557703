export default {
  title: 'Rechnungen hinzufügen',
  hintDocumentTypes: 'Dateiformat',
  hintDocumentTypesDefault: 'PDF',
  hintDocumentTypesInvoice: 'PDF, XML',
  hintMaxFilesCounted:
    'Es kann noch eine Datei hochgeladen werden. | Es können noch bis zu {count} Dateien hochgeladen werden.',
  dropzone: 'Legen Sie Ihre {type} hier ab',
  dropzoneLocation: 'Legen Sie Ihre Rechnungen zum Kunden {location} hier ab',
  filesForUpload: 'Folgende Dateien werden hochgeladen',
  selectVouchersCounted: 'Rechnung auswählen | Rechnungen auswählen',
  upload: 'Jetzt hochladen',
  yourInvoices: 'Ihre Rechnungen',
  uploadError: 'Fehler beim Upload der Datei',
  fileError: 'fehlerhafte Datei oder falscher Dateityp',
  filename: 'Dateiname',
  maxFilesExceeded: 'Die maximale Anzahl an Dateien wurde erreicht.',
  uploadCompleted: 'Ihre Rechnungen wurden erfolgreich hochgeladen.',
  selectNextVouchers: 'Weitere auswählen',
  rules: 'Wichtige Regeln',
  templates: 'Vorlagen',
  tips: 'Tipps',
  uploadVouchers: 'Rechnungen hochladen',
  templatesForDownload: 'Rechnungsvorlagen zum Herunterladen',
  hintAddressMatch:
    'Bitte achten Sie darauf, dass die Zieladresse Ihrer Rechnung mit der des Kunden übereinstimmt. Für abweichende Adressen (z. B. weitere Standorte) nutzen Sie bitte "Belege hinzufügen" in der Navigationsleiste.',
  uploadType: 'Art der Beauftragung',
  standardInvoice: 'Normale Rechnung',
  encashmentDirect: 'Direkt-Inkasso',
  encashmentInvoice: 'Inkasso-Rechnung',
  dateOfFirstDunning: 'Datum der ersten Mahnung',
  encashmentInvoiceInfo:
    'Bereits überfällige und angemahnte Rechnungen können Sie hier direkt zum Inkasso übergeben. Es werden weitere Angaben zu Ihrer Forderung benötigt, welche Sie gleich nach der Belegauswahl hinterlegen können.',
  confirmFactoringRules: {
    title: 'Factoring-Regeln bestätigen',
    text: 'Bitte lesen Sie gründlich unser Dokument zur optimalen Einreichung von Belegen für das Factoring. Sie bestätigen mit dem Klick auf den "Weiter"-Button, dass Sie die Regeln gelesen und verstanden haben.',
    factoringRules: 'Wichtige Regeln für das Factoring'
  }
};
