<template>
  <ValidationObserver ref="form" v-slot="{ failed }" tag="form">
    <app-dialog
      v-model="open"
      primary-action-text="vouchers.openDispute"
      secondary-action-text="navigation.cancel"
      title="vouchers.openDispute"
      :disabled="failed"
      :loading="loading"
      @submit="submitOpenDispute"
    >
      <p>{{ $t('vouchers.dispute.infoOpen') }}</p>

      <app-alert
        v-if="showReversalWarning"
        color="info"
        :icon="false"
        outlined
        class="subtitle-1 mt-6"
      >
        <div class="semi-bold">{{ $t('pleaseNote') }}:</div>
        {{ $t('vouchers.dispute.warningPurchaseReversal') }}
      </app-alert>

      <app-input-row class="mt-4" label="description">
        <app-textarea v-model="formData.message" name="description" rows="3" required />
      </app-input-row>

      <app-input-row label="attachment">
        <app-file-input
          v-model="formData.attachment"
          name="attachment"
          placeholder="choosePDF"
          prepend-inner-icon
          align-right
        />
      </app-input-row>

      <app-input-row class="mt-8 mt-sm-4">
        <app-checkbox
          v-model="confirmOpenDispute"
          label="vouchers.dispute.confirmOpenDispute"
          hide-details
          required
        />
      </app-input-row>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVoucher"
      title="vouchers.dispute.disputeOpened"
      @submit="hideAfterSuccess"
      @close="hideAfterSuccess"
    />
  </ValidationObserver>
</template>

<script>
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/Vouchers/store';
import { PurchaseStatus, BackendStatus } from '@/modules/Vouchers/statics/purchaseStatus';

export default {
  name: 'OpenDisputeDialog',

  mixins: [ShowDialogMixin],

  props: {
    voucher: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    formData: {
      message: '',
      attachment: null
    },
    confirmOpenDispute: false
  }),

  computed: {
    showReversalWarning() {
      if (!this.permissions.voucherDetails.showPurchaseReversalWarning) {
        return false;
      }

      return this.product.withPurchaseAssurance
        ? this.voucher.purchaseStatus === BackendStatus.PURCHASABLE
        : this.purchaseStatusGroups[PurchaseStatus.PURCHASED].includes(this.voucher.purchaseStatus);
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['openDispute']),

    async submitOpenDispute() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      let formData = new FormData();
      formData.append('disputeMessage', this.formData.message);
      formData.append('disputeDocument', this.formData.attachment);

      this.loading = true;
      const { error } = await this.openDispute({
        voucherIdentifier: this.$route.params.id,
        formData
      });
      this.loading = false;

      if (error) {
        return;
      }

      this.submitted = true;
    }
  }
};
</script>
