<template>
  <ValidationObserver ref="form" tag="form">
    <v-container class="pa-0">
      <v-row dense>
        <v-col cols="12">
          <app-text-field v-model="name" label="company" required />
        </v-col>

        <v-col cols="12">
          <app-text-field v-model="contact" label="contactPerson" />
        </v-col>

        <v-col cols="12">
          <app-text-field v-model="streetAndHouse" label="streetAndHouse" :required="!isAdmin" />
        </v-col>

        <v-col cols="4">
          <app-text-field v-model="zip" label="zipCode" rules="required|numeric|length:5" />
        </v-col>

        <v-col cols="8">
          <app-text-field v-model="city" label="city" required />
        </v-col>

        <v-col cols="12">
          <app-text-field v-model="phone" label="phoneNumber" rules="phone" />
        </v-col>

        <v-col cols="12">
          <app-text-field v-model="email" label="email" mode="lazy" rules="email" />
        </v-col>
      </v-row>
    </v-container>
  </ValidationObserver>
</template>

<script>
import DebtorType from '@/modules/Debtors/statics/debtorType';

export default {
  name: 'NewBusinessDebtorForm',

  props: {
    value: {
      type: Boolean,
      default: false
    },
    saveFunc: {
      type: Function,
      required: true
    }
  },

  data: () => ({
    name: '',
    contact: '',
    email: '',
    phone: '',
    streetAndHouse: '',
    city: '',
    zip: ''
  }),

  methods: {
    async saveItem() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      this.$emit('submit', {
        name: this.name,
        contact: this.contact,
        email: this.email,
        phone: this.phone,
        streetAndHouse: this.streetAndHouse,
        zip: this.zip,
        city: this.city,
        type: DebtorType.BUSINESS
      });
    }
  },
  created() {
    this.$emit('update:saveFunc', this.saveItem);
  }
};
</script>

<style scoped>
.col {
  margin-bottom: 1rem;
}
</style>
